<template>
  <div class="payroll">
    <div v-if="isLoadingPayroll">
      <v-skeleton-loader
        data-test="loader"
        v-for="i in 10"
        :key="i"
        type="list-item-avatar"
      />
    </div>
    <div v-else>
      <v-list-item v-for="payroll in payrollItems" :key="payroll.id">
        <PayrollListItem :payroll="payroll" />
      </v-list-item>
      <v-pagination
        :total-visible="page.pageSize"
        v-if="showPagination"
        v-model="page.currentPage"
        @input="fetchPayrollData"
        :length="page.lastPage"
      />
    </div>
    <BaseFooter v-if="showGenerateButton">
      <div class="actions-bar">
        <GeneratePayrollAction />
      </div>
    </BaseFooter>
  </div>
</template>

<script>
import { sortTableItems } from "@/utils/helpers";
import { GET_PAYROLL } from "@/store/modules/invoices/action-types";
import {
  SET_PAYROLL_FILTERS,
  SET_PAYROLL_ITEMS
} from "@/store/modules/invoices/mutation-types";
import { map } from "lodash";
import { createNamespacedHelpers } from "vuex";
import { INVOICE_NAMESPACE } from "@/store/modules/invoices";
import generateId from "uuid/v4";
import { customTableFussyFilter } from "@/utils/filters";
import { AUTH_NAMESPACE } from "@/store/modules/auth";
import GeneratePayrollAction from "@/views/finance/components/Payroll/components/actions/GeneratePayrollAction";
import PayrollListItem from "@/views/finance/components/Payroll/components/PayrollListItem";
import BaseFooter from "@/components/common/BaseFooter";
import { paginationMixin } from "@/mixins/pagination.mixin";

const { mapState: authMapState } = createNamespacedHelpers(AUTH_NAMESPACE);
const { mapState, mapActions, mapMutations } = createNamespacedHelpers(
  INVOICE_NAMESPACE
);

export default {
  name: "Payroll",
  components: {
    GeneratePayrollAction,
    PayrollListItem,
    BaseFooter
  },
  mixins: [paginationMixin],
  props: {
    search: String
  },
  created() {
    this.page = {
      pageSize: 20,
      currentPage: 1,
      lastPage: 1
    };
    this.fetchPayrollData();
  },
  data: () => ({
    filtersId: generateId(),
    availableFilters: {}
  }),
  mounted() {},
  computed: {
    ...mapState({
      payrollItems: state => state.payroll,
      isLoadingPayroll: state => state.isFetchingPayroll,
      payrollFilters: state => state.payrollFilters
    }),
    ...authMapState(["permissions"]),
    searchKeys() {
      return map(this.headers, "value");
    },
    filterCount() {
      return Object.keys(this.payrollFilters).length;
    },
    showGenerateButton() {
      return this.permissions["finance.generate-payroll"];
    },
    requestParams() {
      return {
        filter: this.payrollFilters,
        ...this.requestPageParams,
        sort: "-createdAt",
        include: ""
      };
    }
  },
  methods: {
    ...mapActions({
      getPayroll: GET_PAYROLL
    }),
    ...mapMutations({
      setPayrollFilters: SET_PAYROLL_FILTERS,
      setPayrollItems: SET_PAYROLL_ITEMS
    }),
    customSort(items, arr, isDescArray) {
      const options = { dateValuesLabels: ["periodStart"] };
      return sortTableItems(items, arr, isDescArray, options);
    },
    customFilter(value, searchValue, item) {
      return customTableFussyFilter(searchValue, item, this.searchKeys);
    },
    openDialog() {
      this.$emit("openDialog", "GeneratePayroll");
    },
    // eslint-disable-next-line no-unused-vars
    downloadFile(item) {
      this.$emit("onFileDownload", item.payrollCsv);
    },
    updateFilters(filters) {
      this.setPayrollFilters(filters);
      this.fetchPayrollData();
    },
    clearFilters() {
      this.setPayrollFilters({});
      this.fetchPayrollData();
    },
    async fetchPayrollData() {
      try {
        const { meta, data } = await this.getPayroll(this.requestParams);
        this.availableFilters = meta.filters;

        if (data) {
          if (meta) {
            this.updatePageDetails(meta);
          }
        }
      } catch (error) {
        console.error("Error fetching payroll data:", error);
      }
    }
  }
};
</script>

<style lang="scss">
.actions-bar {
  margin-top: 35px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
